@font-face{
    font-family:    'Avenir Next';
    font-weight:    400;
    src:            url('../fonts/e9167238-3b3f-4813-a04a-a384394eed42.eot?#iefix');
    src:            url('../fonts/e9167238-3b3f-4813-a04a-a384394eed42.eot?#iefix') format('eot'),
                    url('../fonts/2cd55546-ec00-4af9-aeca-4a3cd186da53.woff2') format('woff2'),
                    url('../fonts/1e9892c0-6927-4412-9874-1b82801ba47a.woff') format('woff'),
                    url('../fonts/46cf1067-688d-4aab-b0f7-bd942af6efd8.ttf') format('truetype');
}
@font-face{
    font-family:    'Avenir Next';
    font-weight:    500;
    src:            url('../fonts/1a7c9181-cd24-4943-a9d9-d033189524e0.eot?#iefix');
    src:            url('../fonts/1a7c9181-cd24-4943-a9d9-d033189524e0.eot?#iefix') format('eot'),
                    url('../fonts/627fbb5a-3bae-4cd9-b617-2f923e29d55e.woff2') format('woff2'),
                    url('../fonts/f26faddb-86cc-4477-a253-1e1287684336.woff') format('woff'),
                    url('../fonts/63a74598-733c-4d0c-bd91-b01bffcd6e69.ttf') format('truetype');
}
@font-face{
    font-family:    'Avenir Next';
    font-weight:    600;
    src:            url('../fonts/12d643f2-3899-49d5-a85b-ff430f5fad15.eot?#iefix');
    src:            url('../fonts/12d643f2-3899-49d5-a85b-ff430f5fad15.eot?#iefix') format('eot'),
                    url('../fonts/aad99a1f-7917-4dd6-bbb5-b07cedbff64f.woff2') format('woff2'),
                    url('../fonts/91b50bbb-9aa1-4d54-9159-ec6f19d14a7c.woff') format('woff'),
                    url('../fonts/a0f4c2f9-8a42-4786-ad00-fce42b57b148.ttf') format('truetype');
}
@font-face{
    font-family:    'Avenir Next';
    font-weight:    700;
    src:            url('../fonts/dccb10af-07a2-404c-bfc7-7750e2716bc1.eot?#iefix');
    src:            url('../fonts/dccb10af-07a2-404c-bfc7-7750e2716bc1.eot?#iefix') format('eot'),
                    url('../fonts/14c73713-e4df-4dba-933b-057feeac8dd1.woff2') format('woff2'),
                    url('../fonts/b8e906a1-f5e8-4bf1-8e80-82c646ca4d5f.woff') format('woff'),
                    url('../fonts/890bd988-5306-43ff-bd4b-922bc5ebdeb4.ttf') format('truetype');
}
