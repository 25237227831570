@charset 'utf-8';

@import 'settings';
@import 'foundation';
//@import 'motion-ui';

@include foundation-global-styles;

@include foundation-grid;
// @include foundation-flex-grid;
// @include foundation-xy-grid-classes;

@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
// @include foundation-accordion;
// @include foundation-accordion-menu;
// @include foundation-badge;
// @include foundation-breadcrumbs;
// @include foundation-button-group;
@include foundation-callout;
@include foundation-card;
// @include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
// @include foundation-responsive-embed;
// @include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
// @include foundation-orbit;
// @include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
// @include foundation-switch;
@include foundation-table;
// @include foundation-tabs;
// @include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-flex-classes;

// @include foundation-prototype-classes;

// @include motion-ui-transitions;
// @include motion-ui-animations;

// vendor styles
//@import "vendor/aos";
//@import "vendor/slick-carousel";
//@import "vendor/index-menu";

$fa-font-path: '../fonts' !default;

$nymox-border: 2px solid $red;

@import 'font-awesome';

body, html {
    height: 100%;
}

header.header {
    width: 100%;
    border-bottom: $nymox-border;
    z-index: 5000;
    background-color: $white;
    transition: all 0.25s;
    .has-logo {
        a {
            display: block;
            padding: 1rem 0 0.5rem;
        }
        img {
            transition: all 0.25s;
            width: 50%;
            @include breakpoint(medium up) {
                width: 100%;
            }
        }
    }
    .has-menu {
        @include breakpoint(medium up) {
            height: 84px;
        }
        display: flex;
    }
    &.is-stuck {
        //box-shadow: 0 0 25px 10px $body-background;
        .has-logo {
            img {
                width: 50%;
            }
        }
        .has-menu {
            @include breakpoint(medium up) {
                height: 54px;
            }
        }
    }
}

.main-menu {
    width: 100%;
    > li {
        &.has_active, &:hover {
            > a {
                color: $red;
                &:after {
                    border-color: $red transparent transparent !important;
                }
            }
        }
        > a {
            text-transform: uppercase;
        }
    }

    &.align-right .submenu {
        right: auto !important;
        left: 0 !important;
        &.is-submenu:hover a {
            color: $red;
        }
    }
    &.dropdown .is-dropdown-submenu {
        border: $nymox-border;
        border-top-color: $white;
        //margin-top: 2px;
        .last {
            padding-bottom: 0.75em;
        }
        a {
            &:hover { color: $red; }
            &:before {
                content: '\2013';
                margin-right: 0.25em;
            }
            padding: 0.25rem 1rem;
        }

    }
}

.main {
    min-height: 100%;
    margin-bottom: -($footer-height);
    &:after {
        content: '';
        display: block;
        height: $footer-height
    }
}

/************************/
/*	Content & Sidebar: 	*/
/************************/
.main-content {
    padding: 2rem 0;
    h5,h6 {
        color: $black;
    }
    a {
        color: $red;
        font-weight: $global-weight-bold;
    }
    form {
        input[type='submit'] {
            border-radius: 50%;
            min-width: 90px;
            min-height: 90px;
            text-transform: uppercase;
            box-shadow: 1px 2px 10px $dark-gray;
        }
    }
    blockquote {
        margin: 2rem 3rem;
    }
    p + table {
        margin: 2rem 0;
    }
    ul.lead {
        font-size: 125%;
    }

    strong {
        font-weight: $global-weight-bolder;
    }

    ul:not(.shortlinks) {
        list-style: none;
        li:before {
            content: '\2022';
            color: $red;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
        }
    }
}

$banner-height-medium: 400px;
$banner-height-small: 300px;

//Banner
.banner {
    height: $banner-height-small; //min-height will break the &:after height stuff;
    @include breakpoint(medium up) {
        height: $banner-height-medium;
    }
    background-image: url('../img/banner_bg.jpg');
    background-size: cover;
    &.banner-blue {
        background-image: url('../img/struktur_blue.png');
    }
    &.banner-green {
        background-image: url('../img/struktur_green.png');
    }
    &.banner-orange {
        background-image: url('../img/struktur_orange.png');
    }
    &.banner-red {
        background-image: url('../img/struktur_red.png');
    }
    &.banner-greenblue {
        background-image: url('../img/struktur_greenblue.png');
    }
    position: relative;
    border-top: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;
    @include breakpoint(medium up) {
        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: linear-gradient(to right, rgba($body-background, 0) 50%, rgba($body-background, 0.9) 80%);
            z-index: 10;
        }
    }
    .banner-inner {
        //min-height: 400px;
        position: relative;
        z-index: 20;
        background: rgba($white, 0.45);
        @include breakpoint(medium up) {
            background: linear-gradient(to right, rgba($white, 0.5) 0%, rgba($white, 0.7) 50%, rgba($white, 0) 100%);
        }
        .banner-content {
            position: relative;
            color: $white;
            font-size: 2em;
            text-shadow: 1px 1px 25px $black;
            display: flex;
            align-items: center;
            min-height: $banner-height-small;
            @include breakpoint(medium up) {
                min-height: $banner-height-medium;
                font-size: 3em;
            }
            p {
                line-height: 1.1;
                margin: 0;
                @include breakpoint(medium up) {
                    margin-left: 1em;
                }
            }
            .banner-portrait {
                position: absolute;
                right: -50%;
                top: 0;
                width: 500px;
                @include breakpoint(medium up) {
                    height: $banner-height-medium;
                }
                background-position: center;
                background-repeat: no-repeat;
                display: none;
                @include breakpoint(medium up) {
                    display: flex;
                }
            }
        }
    }
}

.widget {
    h3 {
        @include breakpoint(medium up) {
            font-size: 1.5rem;
        }
        color: $black;
    }
    .menu.vertical {
        li {
            &:before {
                display: none;
            }
        }
    }
    a {
        font-weight: $global-weight-bolder;
    }
}

.row.section {
    padding: 2rem 0;
}
a.anchor {
    display: block;
    position: relative;
    top: -60px;
    visibility: hidden;
}

.has-block-links {
    margin-top: 2rem;
    .block-link {
        .block-border {
            border-top: $nymox-border;
        }
        h4 {
            margin: 0.5rem 0 0.25rem;
            a { font-weight: $global-weight-bolder; }
        }
        a {
            color: inherit;
        }
        img {
            filter: grayscale(35%);
            opacity: 0.85;
            transition-property: filter, opacity;
            transition-duration: 0.25s;
        }
        h4 + a[href="investors/stock-info"] {
            display: block;
            p {
                line-height: 1;
            }
        }
        @include breakpoint(medium up) {
            h2 { font-size: 1.75rem; }
        }
        .last_stock { text-align: center; }
        .last_stock_price {
            font-size: 7rem; //2.5em;
            letter-spacing: -5px;
            color: $dark-gray;
        }
        .last_stock_currency {
            font-size: 2.25rem;
            color: $medium-gray;
        }
        .last_stock_price, .last_stock_currency {
            transition-property: color;
            transition-duration: 0.25s;
        }
        &:hover {
            img {
                filter: grayscale(0);
                opacity: 1;
            }
            .last_stock_price { color: $black; }
            .last_stock_currency { color: $dark-gray; }
        }
    }
}

.team-member {
    margin: 1rem 0;
    h4 {
        border-bottom: $nymox-border;
    }
    .has-team-portrait {
        @include breakpoint(medium up) {
            padding-left: 0;
        }
    }
}

ul.shortlinks {
    list-style: none;
    li {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: left;
        &:not(:last-of-type) { margin-bottom: 1em; }
        &:before {
            content:'';
            position:absolute;
            width: 2em;
            height: 2em;
            background-size: 100% 100%;
        }
        &.sl-contact:before { background-image: url('../img/sidebar/MAil_Pikto.svg'); }
        &.sl-alert:before { background-image: url('../img/sidebar/Stock_Alert.svg'); }
        a {
            padding-left: 3em;
            line-height: 1;
        }
    }
}


/************************/
/*     Footer stuff     */
/************************/
footer.footer {
    min-height: $footer-height; //in _settings.scss
    background-color: $footer-background; //in _settings.scss
    .footer-inner {
        position: relative;
        padding-top: 1rem;
        padding-bottom: 1rem;
        /*
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $red;
            margin: 0 map-get($grid-column-gutter, small)/2;
            @include breakpoint(medium up) {
                margin: 0 map-get($grid-column-gutter, medium)/2;
            }
        }
        */
    }
    .menu a {
        padding: 0.5rem 0;
        &:hover {
            color: $red;
        }
    }
    hr {
        border: 1px solid $red;
        margin: 0;
    }
}
p > small {
    color: $dark-gray;
}
h1,h2,h3,h4,h5,h6 {
    margin-top: 1.25rem;
}
ul.menu.social-links {
    li {
        a {
            transition: all 0.5s;
            color: $light-gray;
            .fa-inverse {
                color: $dark-gray;
            }
        }
        &:hover a {
            &.twitter {
                color: #1da1f2;
                .fa-inverse {
                    color: #fff;
                }
            }
            &.instagram {
                color: #c32aa3;
                .fa-inverse {
                    color: #fff;
                }
            }
            &.linkedin {
                color: #007bb5;
                .fa-inverse {
                    color: #fff;
                }
            }
            &.xing {
                color: #026466;
                .fa-inverse {
                    color: #cfdc00;
                }
            }
            &.facebook {
                color: #3b5998;
                .fa-inverse {
                    color: #fff;
                }
            }
        }
    }
}

.glossary-term {
    text-decoration: underline;
    text-decoration-style: dotted;
}

.glossary-reveal {
    position: relative;
    overflow: visible;
    &:focus {
        outline-color: transparent;
    }
    .close-button {
        position: absolute;
        background-color: $reveal-background;
        top: -22px;
        left: -14px;
        font-size: 2em;
        border: $nymox-border;
        background-color: $red;
        color: $white;
        border-radius: 100%;
        height: 1em;
        width: 1em;
        span {
            display: block;
            margin-top: -3px;
        }
    }
}

form {
    textarea {
        resize: none;
    }
}

@import 'stock';

/************************************************************/
/*     Media queries                                        */
/* http://foundation.zurb.com/sites/docs/media-queries.html */
/************************************************************/

.example-element {
    @include breakpoint(small) {
        background-color: red;
    }
    @include breakpoint(medium) {
        background-color: green;
    }
    @include breakpoint(large) {
        background-color: gold;
    }
    @include breakpoint(xlarge) {
        background-color: yellow;
    }
    @include breakpoint(xxlarge) {
        background-color: white;
    }

    @media print {
        display: none;
    }
}